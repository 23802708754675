<template>
  <template v-if="!isManual">
    <!-- 3.1-3-1 (10) -->
    <st-form-title
      :formTitle="$t('studio.business_bank_setting.individual.question4')"
      required
      class="mt-32"
    />
    <input-text
      name="individual.businessInfo.registrationNo"
      size="lg"
      variant="outline"
      searchable
      :placeholder="$t('studio.business_bank_setting.individual.question4_place_holder')"
      :rules="{
        required: $t('studio.business_bank_setting.individual.enter_no_msg1'),
        number: true,
        max_length: {
          length: 50,
          message: $t('studio.common.def_key.exceed', { length: 50 })
        }
      }"
      :disabled="hasRegistrationNumberInfo && disabled"
      @search="onSearch"
      @update:modelValue="updateRegistrationNo"
    />

    <st-error-message
      v-if="isEmpty(registrationNoError) && !hasBizRegisNum && companyNameError"
      name="individual.businessInfo.name"
    />

    <!-- 3.1-3-1 (11) -->
    <div v-if="hasBizRegisNum" class="mt-12 border-1 border-solid border-border rounded-xl p-20">
      <dl class="flex gap-12 flex-col">
        <div v-for="{ label, value } in bizCardInfo" :key="label" class="flex items-start gap-4">
          <dt
            class="w-[15rem] text-sm leading-md font-regular text-on-surface-elevation-3 shrink-0"
          >
            {{ $t(label) }}
          </dt>
          <dt class="flex-1 text-sm leading-md font-medium text-on-surface-elevation-2 break-all">
            {{ value }}
          </dt>
        </div>
      </dl>
    </div>
  </template>
  <!-- 3.1-3-1-A (11) -->
  <template v-else>
    <!-- 3.1-3-1-A (11-1) -->
    <st-form-title
      :formTitle="$t('studio.business_bank_setting.individual.info_view')"
      required
      class="mt-32"
    />
    <div class="flex flex-col gap-8">
      <div class="flex items-center gap-4">
        <div class="shrink-0 w-[15rem] text-sm font-regular leading-md text-on-surface-elevation-3">
          {{ $t('studio.business_bank_setting.individual.info_view_no') }}
        </div>
        <div class="w-full relative">
          <input-text
            name="individual.businessInfo.registrationNo"
            :placeholder="$t('studio.business_bank_setting.individual.info_view_no_place_holder')"
            :rules="{
              required: $t('studio.business_bank_setting.individual.manual_info_view_no_required'),
              number: true,
              max_length: {
                length: 50,
                message: $t('studio.common.def_key.exceed', { length: 50 })
              }
            }"
            :disabled="disabled"
          />
        </div>
      </div>

      <!-- 3.1-3-1-A (11-2) -->
      <div class="flex items-center gap-4">
        <div class="shrink-0 w-[15rem] text-sm font-regular leading-md text-on-surface-elevation-3">
          {{ $t('studio.business_bank_setting.individual.info_view_type') }}
        </div>
        <div class="w-full relative">
          <radio-group
            name="individual.businessInfo.businessType"
            :options="optionBusinessType"
            :disabled="disabled"
            :optionProps="{
              size: 'sm',
              align: 'middle'
            }"
            class="flex-1 flex items-center gap-24"
          />
        </div>
      </div>

      <!-- 3.1-3-1-A (11-3) -->
      <div class="flex items-center gap-4">
        <div class="shrink-0 w-[15rem] text-sm font-regular leading-md text-on-surface-elevation-3">
          {{ $t('studio.business_bank_setting.individual.info_view_b_name') }}
        </div>
        <div class="w-full relative">
          <input-text
            name="individual.businessInfo.name"
            :placeholder="
              $t('studio.business_bank_setting.individual.info_view_b_name_place_holder')
            "
            :rules="{
              required: $t(
                'studio.business_bank_setting.individual.manual_info_view_b_name_required'
              ),
              max_length: {
                length: 50,
                message: $t('studio.common.def_key.exceed', { length: 50 })
              }
            }"
            :disabled="disabled"
          />
        </div>
      </div>

      <!-- 3.1-3-1-A (11-4) -->
      <div class="flex items-center gap-4">
        <div class="shrink-0 w-[15rem] text-sm font-regular leading-md text-on-surface-elevation-3">
          {{ $t('studio.business_bank_setting.individual.info_view_r_name') }}
        </div>
        <div class="w-full relative">
          <input-text
            name="individual.businessInfo.ceoName"
            :placeholder="
              $t('studio.business_bank_setting.individual.info_view_r_name_kr_place_holder')
            "
            :rules="{
              required: $t(
                'studio.business_bank_setting.individual.manual_info_view_r_name_kr_required'
              ),
              max_length: {
                length: 50,
                message: $t('studio.common.def_key.exceed', { length: 50 })
              }
            }"
            :disabled="disabled"
          />
        </div>
      </div>

      <!-- 3.1-3-1-A (11-5) -->
      <div class="flex items-center gap-4">
        <div class="shrink-0 w-[15rem] text-sm font-regular leading-md text-on-surface-elevation-3">
          {{ $t('studio.business_bank_setting.individual.info_view_b_type') }}
        </div>
        <div class="w-full relative">
          <input-text
            name="individual.businessInfo.type"
            :placeholder="
              $t('studio.business_bank_setting.individual.info_view_b_type_place_holder')
            "
            :rules="{
              required: $t(
                'studio.business_bank_setting.individual.manual_info_view_b_type_required'
              ),
              max_length: {
                length: 50,
                message: $t('studio.common.def_key.exceed', { length: 50 })
              }
            }"
            :disabled="disabled"
          />
        </div>
      </div>

      <!-- 3.1-3-1-A (11-6) -->
      <div class="flex items-center gap-4">
        <div class="shrink-0 w-[15rem] text-sm font-regular leading-md text-on-surface-elevation-3">
          {{ $t('studio.business_bank_setting.individual.info_view_b_items') }}
        </div>
        <div class="w-full relative">
          <input-text
            name="individual.businessInfo.subBizDescription"
            :placeholder="
              $t('studio.business_bank_setting.individual.info_view_b_items_place_holder')
            "
            :rules="{
              required: $t(
                'studio.business_bank_setting.individual.manual_info_view_b_items_required'
              ),
              max_length: {
                length: 50,
                message: $t('studio.common.def_key.exceed', { length: 50 })
              }
            }"
            :disabled="disabled"
          />
        </div>
      </div>

      <!-- 3.1-3-1-A (11-7) -->
      <div class="flex items-center gap-4">
        <div class="shrink-0 w-[15rem] text-sm font-regular leading-md text-on-surface-elevation-3">
          {{ $t('studio.business_bank_setting.individual.info_view_adress') }}
        </div>
        <div class="w-full relative">
          <input-text
            name="individual.businessInfo.detailAddress"
            :placeholder="
              $t('studio.business_bank_setting.individual.info_view_adress_place_holder')
            "
            :rules="{
              required: $t(
                'studio.business_bank_setting.individual.manual_info_view_adress_required'
              ),
              max_length: {
                length: 50,
                message: $t('studio.common.def_key.exceed', { length: 50 })
              }
            }"
            :disabled="disabled"
          />
        </div>
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import { isEmpty } from 'lodash';
import { useFieldError, useSetFieldError } from 'vee-validate';

import StFormTitle from '@/components/common/st-form-title.vue';
import InputText from '@/components/validation/input-text.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import StErrorMessage from '@/components/validation/st-error-message.vue';
import type { FormOption } from '@/types/common/form.type';

const props = defineProps<{
  disabled?: boolean;
  isManual?: boolean;
  hasBizRegisNum?: boolean;
  bizCardInfo: FormOption[];
  optionBusinessType: FormOption[];
  hasRegistrationNumberInfo: boolean;
}>();

const emits = defineEmits(['search']);

const companyNameError = useFieldError('individual.businessInfo.name');
const registrationNoError = useFieldError('individual.businessInfo.registrationNo');
const setCompanyNameError = useSetFieldError('individual.businessInfo.name');

const updateRegistrationNo = () => {
  setCompanyNameError('');
};

const onSearch = () => {
  if (props.disabled) {
    return;
  }
  setCompanyNameError('');
  emits('search');
};
</script>
